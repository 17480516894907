







































import { Component, Prop, Vue } from 'vue-property-decorator';
import Job from '@/models/Job';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import User from '@/models/User';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import { CalendarEvent } from 'vuetify';
import {getJobTime} from '@/helper/JobTimeHelper';

@Component({
    components: {
        UserInitialsComponent: () => import(
            /* webpackChunkName: "UserInitialsComponent" */
            '@/components/user/UserInitials.component.vue'),
    },
})
export default class JobTooltipComponent extends Vue {
    @Prop()
    public data!: { job: Job, userData: JobUserDataInterface[] } & CalendarEvent;

    /**
     * Retrieves the Job time by using a helper function
     * @param job
     */
    public retrieveJobTime = (job: Job) => getJobTime(job);
}

